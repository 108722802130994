import {
  useState,
  type ReactNode,
  createContext,
  useContext,
  type MouseEventHandler,
} from "react";
import { Inline, Stack, Text, Button } from "~/components/vendorUI";
import { Icon } from "@capterra/vendor-ui-components-icon";
import { Para, anchorCSS } from "~/components/Text";
import { useTranslation } from "~/utils";
import { css, cva } from "ui/css";

const DrawerContext = createContext({
  contentOpen: false,
  toggleBody: () => {},
});
interface ChildrenProps {
  children?: ReactNode;
}
interface DrawerProps extends ChildrenProps {
  show: boolean;
}
interface DrawerHeaderProps extends ChildrenProps {
  pendingCount?: number;
  isSubmitting?: boolean;
  isSaveDisabled?: boolean;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  onSubmit?: MouseEventHandler<HTMLButtonElement>;
  description?: ReactNode;
  // used for Pendo tracking
  dataGtmSave?: string;
  dataGtmCancel?: string;
  // used for GA4 tracking
  saveTrackingId?: string;
  cancelTrackingId?: string;
}

const Header = ({
  children = <></>,
  description,
  pendingCount,
  isSubmitting = false,
  isSaveDisabled = false,
  onCancel = () => {},
  onSubmit = () => {},
  dataGtmSave,
  dataGtmCancel,
  saveTrackingId,
  cancelTrackingId,
}: DrawerHeaderProps) => {
  const { t } = useTranslation();
  return (
    <Inline className={drawerHeaderCSS} align="center">
      {typeof pendingCount === "number" && pendingCount > 0 && (
        <div className={changeCountCSS} data-testid="vpDrawerCount">
          {pendingCount}
        </div>
      )}
      <Inline align="center">
        <Text size="20px">
          {t("DRAWER_PENDING_TITLE", { count: pendingCount || 2 })}
        </Text>
        {description || <Para>{t("DRAWER_PENDING_DESCRIPTION")}</Para>}
      </Inline>
      {children}
      <Stack className={drawerCTABoxCSS} gap="8px" align="center">
        <Button
          type="submit"
          disabled={isSaveDisabled || isSubmitting}
          isLoading={isSubmitting}
          onClick={onSubmit}
          data-testid="vpDrawerSubmit"
          data-gtm={dataGtmSave}
          data-tracking={saveTrackingId}
        >
          {t("DRAWER_ACTION_SAVE")}
        </Button>
        <button
          className={anchorCSS({ variant: "inverted" })}
          type="reset"
          disabled={isSubmitting}
          onClick={onCancel}
          data-testid="vpDrawerCancel"
          data-gtm={dataGtmCancel}
          data-tracking={cancelTrackingId}
        >
          {t("DRAWER_ACTION_CANCEL")}
        </button>
      </Stack>
    </Inline>
  );
};

const Trigger = ({ children = <></> }: ChildrenProps) => {
  const { contentOpen, toggleBody } = useContext(DrawerContext);
  return (
    <Inline align="center" gap="8px" asChild>
      <button
        className={drawerToggleCSS}
        onClick={toggleBody}
        data-testid="vpDrawerToggle"
      >
        <Icon
          className={toggleIconCSS({ isContentOpen: contentOpen })}
          name="ChevronDown"
          color="white"
        />
        {children}
      </button>
    </Inline>
  );
};

const Body = ({ children = <></> }: ChildrenProps) => {
  const { contentOpen } = useContext(DrawerContext);
  return (
    <div
      className={drawerContentCSS({ show: contentOpen })}
      data-testid={`vpDrawerBody-${contentOpen ? "show" : "hide"}`}
    >
      {children}
    </div>
  );
};

export const Drawer = ({ show, children }: DrawerProps) => {
  const [contentOpen, setContentOpen] = useState(false);
  const toggleBody = () => setContentOpen((s) => !s);

  return (
    <DrawerContext.Provider value={{ contentOpen, toggleBody }}>
      <div
        className={backdropCSS({ show: contentOpen })}
        onClick={toggleBody}
        data-testid={`vpDrawerBackdrop-${contentOpen ? "show" : "hide"}`}
      />
      <div
        className={drawerRootCSS({
          show: show ? (!contentOpen ? "header" : "body") : undefined,
        })}
        data-testid={`vpDrawer-${show ? "open" : "closed"}`}
      >
        {children}
      </div>
    </DrawerContext.Provider>
  );
};

Drawer.Header = Header;
Header.Trigger = Trigger;
Drawer.Body = Body;

export const ANIMATION_DURATION_IN_MS = 250;

const backdropCSS = cva({
  base: {
    position: "fixed",
    inset: 0,
    background: "{colors.black/50}",
    zIndex: "overlay",
    transition: `opacity ${ANIMATION_DURATION_IN_MS}ms ease-in-out`,
  },

  variants: {
    show: {
      true: {
        opacity: 1,
        pointerEvents: "all",
      },
      false: {
        opacity: 0,
        pointerEvents: "none",
      },
    },
  },
});

const drawerRootCSS = cva({
  base: {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    paddingBlock: 0,
    paddingInline: "var(--pageSidePadding)",
    background: "brandBlue",
    color: "white",
    zIndex: "850",
    transition: `transform ${ANIMATION_DURATION_IN_MS}ms ease-in-out`,
    transform: "translateY(calc(100% + token(spacing.16px)))",
  },

  variants: {
    show: {
      header: {
        transform: "translateY(calc(100% - 106px))",
      },
      body: {
        transform: "translateY(0)",
      },
    },
  },
});

const drawerHeaderCSS = css({
  padding: "8px",
  position: "relative",
  height: "106px",
});

const drawerContentCSS = cva({
  variants: {
    show: {
      true: {
        paddingBlock: "16px",
        paddingInline: "8px",
      },
      false: {
        transitionDelay: `${ANIMATION_DURATION_IN_MS}ms`,
        transitionProperty: "padding",
        padding: 0,
      },
    },
  },
});

const drawerToggleCSS = css({
  position: "absolute",
  top: "-16px",
  padding: "8px",
  minWidth: "40px",
  background: "brandBlue",
  color: "white",
  justifyContent: "center !important",
  borderRadius: "4px",
  border: "none",
  outline: "none",
  cursor: "pointer",
  userSelect: "none",
});

const toggleIconCSS = cva({
  base: { transition: `transform ${ANIMATION_DURATION_IN_MS}ms ease-in-out` },
  variants: {
    isContentOpen: {
      true: {
        transform: "rotate(-180deg)",
      },
      false: {
        transform: "rotate(0deg)",
      },
    },
  },
});

const changeCountCSS = css({
  width: 40,
  height: 40,
  borderRadius: "50%",
  background: "tangerine",
  fontWeight: "bold",
  fontSize: "20px",
  display: "grid",
  placeItems: "center",
  boxShadow: "0 0 0 5px #003b60",
});

const drawerCTABoxCSS = css({ marginLeft: "auto" });
